'use client';
import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
import { useEffect } from 'react';

/**
 * Global errors handling, catches errors across all routes
 */
export default function GlobalError({ error }: { error: Error }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body className="bg-white">
        <Error statusCode={undefined as any} />
      </body>
    </html>
  );
}
